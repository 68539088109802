<ng-container *ngIf="product">
  <mat-drawer-container hasBackdrop="false">
    <mat-drawer #drawer mode="over" position="end">
      <ng-container *ngIf="sidNavType == 1">
        <ng-container *ngIf="!loginUser">
          <div class="auth-wrapper-product xs:w-screen">
            <button mat-icon-button color="warn" class="absolute right-2-rem top-1-rem cursor-pointer"
              (click)="drawer.close()" style="z-index: 2">
              <mat-icon>close</mat-icon>
            </button>
            <im-auth-comp isItComonent="true" (onSuccess)="loginSuccess($event, drawer, false)"></im-auth-comp>
          </div>
        </ng-container>

        <div class="activation-code-wrapper xs:w-auto" *ngIf="loginUser">
          <button mat-icon-button color="warn" class="absolute right-2-rem top-1-rem cursor-pointer"
            (click)="drawer.close()" style="z-index: 2">
            <mat-icon>close</mat-icon>
          </button>
          <div class="screen">
            <div class="flex flex-column">
              <h3 class="title poppins-font font-2xl">Enter your Given Key</h3>
              <p class="mt-2 line-height-15rem opacity-75">
                We thank you for this purchase and assure you of our committed
                support. <b>Enter</b> the 16-digit Activation code to unlock
                course and learning videos. Book your FREE Trainer Sessions.
              </p>
            </div>

            <div class="form-container mt-6">
              <div class="form-title mb-2">
                <b class="letter-spacing-15">ACTIVATION CODE</b>
              </div>
              <form (ngSubmit)="validateActivationCode()">
                <div style="margin-right: 1rem">
                  <input id="activation-code" name="activation-code" type="text" placeholder="XXXX-XXXX-XXXX-XXXX"
                    required class="input" [(ngModel)]="key" (ngModelChange)="onKeyChange()" maxlength="19" />
                  <span *ngIf="errorProductKeyMessage" class="mat-error mt-2 flex font-semi-bold">{{
                    errorProductKeyMessage }}</span>
                </div>
                <div>
                  <button type="submit" class="button" [disabled]="!key && key.length != 16">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="sidNavType == 2">
        <div style="width: 33vw" class="auth-wrapper-product">
          <button mat-icon-button color="warn" class="absolute right-2-rem top-1-rem cursor-pointer"
            (click)="drawer.close()" style="z-index: 2">
            <mat-icon>close</mat-icon>
          </button>
          <im-auth-comp isItComonent="true" (onSuccess)="loginSuccess($event, drawer, true)"></im-auth-comp>
        </div>
      </ng-container>
    </mat-drawer>
    <mat-drawer-content>
      <div
        class="im-product-details-wrapper grid has-12-columns has-1-rows justify-items-stretch h-screen column-gap-0 chapter-topic-view-section">
        <div
          class="main column-start-1 row-span-1 column-end-8 xxl:column-end-8 xs:column-end-12 relative overflow-y-auto">
          <div class="w-full cover-image bg-no-repeat course-header" *ngIf="product.properties?.header">

            <div class="content flex justify-content-space-between xs:flex-column xs:justify-center">
              <div class="flex flex-column w-65p xs:w-full xs:align-items-center">
                <div class=" flex align-items-center">
                  <button mat-icon-button class="back_button mr-4" routerLink="/our-offering/bot-based-courses"
                    style=" background: #e0e0e0;">
                    <mat-icon>keyboard_backspace</mat-icon>
                  </button>
                  <img [src]="product.properties?.header?.logo" width="140" class="mr-8" />

                  <div class="flex align-items-center" *ngIf="product.properties?.versions?.length > 0">
                    <span class="font-semi-bold"> Select Version:</span>
                    <ng-container *ngFor="let version of product.properties?.versions">
                      <div class="flex ml-2">
                        <mat-chip-list aria-label="Product Category">
                          <mat-chip [selected]="version.key == productVersion"
                            class="text-uppercase letter-spacing-10 font-xsss" style="cursor: pointer"
                            (click)="changeProductVersion(version)">{{version.label}}</mat-chip>
                        </mat-chip-list>
                      </div>
                    </ng-container>

                  </div>
                </div>
                <p class="mt-3 xs:display-none font-sm" style="width: 85%; line-height: 1.4rem">
                  {{ product.properties?.header?.short_description }}
                </p>
              </div>
              <div class="w-35p xs:w-full xs:justify-center xs:flex xs:mt-4">
                <img [src]="product.properties?.header?.image" class="w-90p xs:w-3/5" />
              </div>
              <div></div>



            </div>

          </div>

          <mat-tab-group dynamicHeight (selectedTabChange)="mainTabChange($event)" class="main-tab"
            [selectedIndex]="selectedIndex">
            <mat-tab>
              <ng-template mat-tab-label>About</ng-template>
              <div>
                <div class="chapter-section im-content flex">
                  <div id="editorjs"></div>
                </div>
              </div>
            </mat-tab>
            <mat-tab [disabled]="!loginUser || !userProduct">
              <ng-template mat-tab-label>Schematic</ng-template>
              <div class="flex justify-center" *ngIf="product.properties?.schematic && schematicUrl">
                <div class="w-full">
                  <div class="px-3 py-3 w-full font-xs" style="
                  line-height: 1.2rem;
                  background-color: #fcfbfe;
                  font-weight: 500;
                ">
                    A schematic diagram is a simplified graphical representation of a circuit, using symbols for
                    components and lines for connections. It’s essential in engineering and electronics for
                    designing, analyzing, and troubleshooting circuits.
                  </div>

                  <iframe [src]="schematicUrl" class="w-full h-full mx-3" style="
                      border: 1px solid rgb(218, 220, 224);
                      width: calc(100% - 1.5rem) !important;
                      border-radius: 8px;
                      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
                      height: 82.49vh !important;
                      margin-bottom: 1rem;
                    "></iframe>

                  <ng-container *ngIf="extra_schematics?.length>0">
                    <div *ngFor="let extra_schematic of extra_schematics">
                      <h2 class="mx-3 mb-4">{{extra_schematic.title}}</h2>
                      <iframe [src]="extra_schematic.url" class="w-full h-full mx-3" style="
                        border: 1px solid rgb(218, 220, 224);
                        width: calc(100% - 1.5rem) !important;
                        border-radius: 8px;
                        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
                        height: 82.49vh !important;
                        margin-bottom: 1rem;
                      "></iframe>
                    </div>
                  </ng-container>

                </div>
              </div>
            </mat-tab>
            <mat-tab [disabled]="!loginUser || !userProduct">
              <ng-template mat-tab-label>Projects</ng-template>
              <div class="practical-section">
                <mat-tab-group (selectedTabChange)="practicalTabChange($event)" [selectedIndex]="selectedProjectIndex">
                  <ng-container *ngFor="let practical of productCodes; let i = index">
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span class="mr-1 border px-1 font-semi-bold border-radius-md">P{{ i + 1 }}</span>
                        {{ practical.name }}
                      </ng-template>
                      <div class="ml-5 mt-5 mr-5">
                        <h3 class="flex justify-between">
                          {{ practical.name }}
                          <span
                            class="border px-2 py-1 font-semi-bold font-sm mat-color-primary border-dashed border-radius-md">Project
                            {{ i + 1 }}</span>
                        </h3>
                        <div class="im-content chapter-section mt-2">
                          <div id="editorjs{{ practical.id }}"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </ng-container>
                </mat-tab-group>
              </div>
            </mat-tab>

            <mat-tab [disabled]="!loginUser || !userProduct">
              <ng-template mat-tab-label>Self Learn</ng-template>
              <div class="practical-section">
                <mat-tab-group (selectedTabChange)="videoTabChange($event)" [selectedIndex]="selectedProjectIndex">
                  <ng-container *ngFor="let practical of selfLearnVideos; let i = index">
                    <mat-tab>
                      <ng-template mat-tab-label>
                        {{ practical.name }}
                      </ng-template>
                      <div class="ml-5 mt-5 mr-5">
                        <h3 class="flex justify-between">
                          {{ practical.name }}
                        </h3>
                        <div class="im-content chapter-section mt-2">
                          <div id="videoEditorjs{{ practical.id }}"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </ng-container>
                </mat-tab-group>
              </div>
            </mat-tab>
            <mat-tab [disabled]="!loginUser || !userProduct">
              <ng-template mat-tab-label>Book a Free Sessions</ng-template>
              <div class="flex p-4">
                <div class="w-1/4">
                  <div style="opacity: 1; transform: none">
                    <img alt="Suresh Kadari" class="border-radius-full" src="https://schoolforai.in/images/sureshk.png"
                      width="50%" />
                    <h1 class="font-xl font-semi-bold my-2">Suresh Kadari</h1>
                    <div class="flex items-start justify-start font-sm">
                      <p>
                        Book a Session as per your convenience. We are here to
                        support with your learning curve. Innovation & Engaged
                        learning are our lifelines.
                      </p>
                    </div>
                    <div class="flex flex-column my-2">
                      <div class="flex items-start justify-start font-sm my-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="relative z-20 mr-2 mt-[2px] h-4 w-4 flex-shrink-0 rtl:ml-2">
                          <circle cx="12" cy="12" r="10"></circle>
                          <polyline points="12 6 12 12 16 14"></polyline>
                        </svg>
                        <div class="relative z-10 max-w-full break-words">
                          45 mins max
                        </div>
                      </div>
                      <div class="flex items-start justify-start font-sm my-2">
                        <div class="relative z-10 max-w-full break-words">
                          <div class="text-default mr-6 flex w-full flex-col space-y-4 text-sm mr-2">
                            <div class="flex flex-row items-center text-sm font-medium">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="text-default h-4 w-4 ltr:mr-[10px] rtl:ml-[10px]">
                                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                              </svg>
                              <p class="line-clamp-1 ml-2" data-state="closed">
                                Link meeting
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="flex items-start justify-start font-sm my-2 cursor-pointer [&amp;_.current-timezone:before]:focus-within:opacity-100 [&amp;_.current-timezone:before]:hover:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="relative z-20 mr-2 mt-[2px] h-4 w-4 flex-shrink-0 rtl:ml-2">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="2" x2="22" y1="12" y2="12"></line>
                          <path
                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                          </path>
                        </svg>
                        <div class="z-10 break-words relative max-w-[90%]">
                          <span
                            class="min-w-32 current-timezone before:bg-subtle -mt-[2px] flex h-6 max-w-full items-center justify-start before:absolute before:inset-0 before:bottom-[-3px] before:left-[-30px] before:top-[-3px] before:w-[calc(100%_+_35px)] before:rounded-md before:py-3 before:opacity-0 before:transition-opacity">
                            <div class="max-w-full css-b62m3t-container">
                              <span id="react-select-2-live-region" class="css-7pg0cj-a11yText"></span><span
                                aria-live="polite" aria-atomic="false" aria-relevant="additions text"
                                class="css-7pg0cj-a11yText"></span><span data-testid="select-control">
                                <div
                                  class="!cursor-pointer border-default text-sm leading-4 placeholder:text-sm placeholder:font-normal focus-within:ring-emphasis hover:border-emphasis rounded-md gap-1 !min-h-0 p-0 w-full border-0 bg-transparent focus-within:ring-0 css-cp01gg-control">
                                  <div class="text-emphasis placeholder:text-muted flex gap-1 css-14oxtc6">
                                    <div class="placeholder:text-muted text-text py-1 css-w54w9q-singleValue">
                                      <p class="truncate">Asia/Kolkata</p>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-2/5">
                  <mat-calendar (selectedChange)="onCalendarSelectedChange($event)" [selected]="selectedDay"
                    startView="month" class="w-full" [startAt]="selectedDay" [minDate]="todayDay" [maxDate]="maxDay"
                    [dateFilter]="appointmentDateSelectionFilter"></mat-calendar>
                </div>
                <div class="relative" style="width: 35%">
                  <div class="flex justify-end mr-2">
                    <div class="text-right flex items-center">
                      <span class="color-secondary mr-3">Available Free Sessions</span>
                      <h1 style="color: #4073b9" class="font-5xl">
                        {{ 2 - userProductSessions.length }}
                      </h1>
                    </div>
                  </div>
                  <div class="flex items-center justify-center" *ngIf="activetimeSlot.length == 0" style="height: 80%">
                    <p class="mat-color-primary">No Timeslots Available</p>
                  </div>
                  <div class="flex mt-4 flex-column ml-12 mr-3 p-4 border-radius-lg justify-between"
                    *ngIf="activetimeSlot.length > 0" style="
                      background-color: white;
                      width: max-content;
                      height: 40vh !important;
                    ">
                    <div>
                      <h3 class="mb-4">
                        {{ selectedDay.format("MMM Do, dddd") }}
                      </h3>
                      <ul class="list-unstyled">
                        <li *ngFor="let slot of activetimeSlot"
                          class="border-radius-lg border p-4 cursor-pointer text-center" style="width: 10vw"
                          (click)="selectedTimeSlot = slot" [ngClass]="{
                            'timeslot-active':
                              selectedTimeSlot?.value == slot.value
                          }" [attr.disabled]="checkSlotAlreadyBooked(slot)">
                          {{ slot.title }}
                        </li>
                      </ul>
                    </div>
                    <button
                      class="mat-focus-indicator letter-spacing-10 text-uppercase font-xss mat-raised-button mat-button-base mat-primary cursor-pointer"
                      [disabled]="!selectedTimeSlot" (click)="bookSlot()" style="background-color: #4073b9">
                      Confirm the slot
                    </button>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="column-start-9 column-end-12 xs:display-none relative" style="background-color: #fff">
          <ng-container *ngIf="userProduct">
            <div class="mt-4 mx-4 flex flex-column">
              <img src="/assets/images/congrats.png" width="180" class="self-center" />
              <p class="line-height-15rem opacity-75 mt-2">
                I personally thank you for this purchase and assure you of our
                committed support. Explore our other courses and bots here,
                which are helping children to master concepts from Robotics, IOT
                to AI.
              </p>
              <p class="line-height-15rem opacity-75 mt-2">
                We promote emerging technologies hence, all our parts are 3D
                printed and handcrafted. I am sure you would appreciate and
                enjoy this product and excuse imperfections, if any.
              </p>
              <div class="flex justify-between mt-4 items-center">
                <div class="w-1/2">
                  <img alt="Suresh Kadari" class="border-radius-full" src="https://schoolforai.in/images/sureshk.png"
                    width="20%" />
                  <h1 class="font-base letter-spacing-10 font-medium mt-2">
                    SURESH KADARI
                  </h1>
                  <p class="font-medium">C.E.O</p>
                </div>

                <div class="flex flex-column">
                  <span class="mb-2">For Support :</span>
                  <a class="flex items-center" target="_blank" href="https://wa.me/9949296431">
                    <span class="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="32px" height="32px"
                        style="fill: #00a884">
                        <path
                          d="M 7.5 1 C 3.914063 1 1 3.914063 1 7.5 C 1 8.796875 1.417969 9.984375 2.070313 11 L 0.953125 14.121094 L 4.429688 13.140625 C 5.351563 13.644531 6.375 14 7.5 14 C 11.085938 14 14 11.085938 14 7.5 C 14 3.914063 11.085938 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 6.457031 13 5.492188 12.707031 4.65625 12.203125 L 4.472656 12.089844 L 2.546875 12.628906 L 3.171875 10.894531 L 3.019531 10.683594 C 2.378906 9.78125 2 8.6875 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 5.0625 4 C 4.949219 4 4.761719 4.046875 4.601563 4.234375 C 4.445313 4.417969 4 4.867188 4 5.78125 C 4 6.691406 4.617188 7.574219 4.703125 7.699219 C 4.789063 7.824219 5.917969 9.695313 7.644531 10.5 C 8.054688 10.691406 8.375 10.804688 8.625 10.890625 C 9.039063 11.03125 9.414063 11.011719 9.710938 10.964844 C 10.042969 10.910156 10.730469 10.515625 10.875 10.082031 C 11.015625 9.648438 11.019531 9.277344 10.972656 9.199219 C 10.933594 9.121094 10.816406 9.074219 10.644531 8.984375 C 10.472656 8.890625 9.625 8.441406 9.46875 8.382813 C 9.3125 8.316406 9.195313 8.289063 9.078125 8.472656 C 8.964844 8.660156 8.636719 9.078125 8.535156 9.199219 C 8.433594 9.324219 8.335938 9.339844 8.164063 9.246094 C 7.988281 9.152344 7.433594 8.957031 6.777344 8.324219 C 6.265625 7.832031 5.917969 7.226563 5.820313 7.039063 C 5.71875 6.851563 5.808594 6.753906 5.894531 6.664063 C 5.972656 6.578125 6.066406 6.445313 6.152344 6.335938 C 6.238281 6.230469 6.265625 6.148438 6.324219 6.027344 C 6.382813 5.902344 6.355469 5.792969 6.3125 5.703125 C 6.265625 5.609375 5.921875 4.695313 5.78125 4.324219 C 5.640625 3.964844 5.496094 4.011719 5.390625 4.007813 C 5.292969 4 5.175781 4 5.0625 4 Z" />
                      </svg>
                    </span>
                    <span class="font-semi-bold">+91-9949296431</span>
                  </a>
                </div>
              </div>
              <div class="mt-6">
                <div>
                  <ng-container *ngFor="let transaction of history">
                    <div class="p-4 border-dashed border-radius-md border-radius-lg item mb-4 flex justify-between"
                      style="border-color: #00a884">
                      <div class="flex flex-column line-height-2rem">
                        <p class="font-xs sub-title opacity-75">
                          {{ transaction.modified_at | date : "medium" }}
                        </p>
                        <p class="font-sm" *ngIf="transaction.order_number">
                          <span class="sub-title">Order Number: </span>
                          {{ transaction.order_number }}
                        </p>
                        <p class="font-sm">
                          <span class="sub-title font-medium">Activation Code:
                          </span>
                          {{ transaction.product_key?.key }}
                        </p>
                      </div>
                      <div class="flex flex-column items-center justify-center" style="gap: 8px">
                        <p class="currency font-semi-bold">
                          {{
                          transaction.price
                          | currency : "INR" : "symbol" : "2.0-0"
                          }}
                        </p>
                        <div class="border-radius-full failure-icon flex items-center justify-center"
                          *ngIf="transaction.status === 'Canceled'">
                          <div class="border-radius-full flex items-center justify-center">
                            <mat-icon aria-hidden="false" aria-label="Canceled icon">close</mat-icon>
                          </div>
                        </div>
                        <div class="border-radius-full flex items-center justify-center success-icon"
                          *ngIf="transaction.status === 'Success'">
                          <div class="border-radius-full flex items-center justify-center">
                            <mat-icon aria-hidden="false" aria-label="Success icon">check</mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="mt-6 flex justify-between items-center mb-2">
                  <h4 class="letter-spacing-10 font-light mat-color-primary">
                    YOUR SESSIONS
                  </h4>
                  <button
                    class="mat-focus-indicator letter-spacing-15 text-uppercase font-xss mat-raised-button mat-button-base mat-primary cursor-pointer"
                    (click)="mainTabChange({ index: 4 })">
                    BOOK A FREE SESSIONS
                  </button>
                </div>
                <div class="flex flex-column">
                  <div *ngFor="let schedule of userProductSessions" class="flex mt-4 px-0 cursor-pointer items-center">
                    <div
                      class="mr-4 course1-text course-border-top border-radius-lg shadow-1 flex items-center flex-column"
                      style="min-width: 3.2rem; border: 0">
                      <div style="
                          font-size: 11px;
                          border-radius: 8px 8px 0 0;
                          background-color: currentColor;
                          width: 100%;
                        " class="text-center">
                        <span style="color: #fff">{{ schedule.event_date | date : "MMM" }}
                          {{ schedule.event_date | date : "dd" }}
                        </span>
                      </div>
                      <div class="flex flex-column items-center" style="padding: 3px 2px 0 2px">
                        <p class="font-bolder text-center">
                          {{
                          schedule.event_date +
                          "
                          " +
                          schedule.event_time | date : "h:mm"
                          }}
                        </p>
                        <p class="font-xs time-prefix">
                          {{
                          schedule.event_date +
                          "
                          " +
                          schedule.event_time | date : "a"
                          }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p class="font-medium">{{ product.name }}</p>
                      <p class="font-xs line-clamp-1 font-light mt-1">
                        Your learning and play bot
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="userProductSessions.length == 0">
                  <div class="empty-state flex items-center flex-column justify-center mt-12">
                    <mat-icon class="border-radius-full"
                      style="height: 50px; width: 50px; font-size: 32px">search</mat-icon>
                    <p class="text-center mt-2 color-secondary">
                      No schedules found.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ngcontainer *ngIf="getthePackages && !userProduct">
            <div class="mt-4 mx-4" *ngFor="let package of packages">
              <div class="text-uppercase letter-spacing-10 mat-color-primary font-medium">
                MAKE IT YOURS
              </div>
              <h3 class="font-3xl poppins-font" style="line-height: 2.5rem">
                {{ package.name }}
              </h3>
              <p class="mt-2 opacity-75" style="line-height: 1.4rem">
                {{ package.short_description }}
              </p>
              <div class="mt-4 flex justify-center" style="height: 30vh">
                <img [src]="package.properties.image" />
              </div>
              <div class="flex flex-column" *ngIf="product.properties?.custom_name">
                <mat-form-field appearance="outline">
                  <mat-label>Name on the Bot</mat-label>
                  <input matInput placeholder="" />
                  <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Message</mat-label>
                  <textarea matInput rows="3"></textarea>
                  <mat-icon matSuffix class="material-symbols-outlined">speaker_notes</mat-icon>
                </mat-form-field>
              </div>
              <div class="flex justify-between mt-4 items-center w-full absolute bottom-20">
                <div class="flex flex-column line-height-15rem ml-2">
                  <p>
                    Already Purchased <b>{{ product.name }}</b>?
                  </p>
                  <a class="text-uppercase letter-spacing-5 mat-color-primary poppins-font cursor-pointer"
                    (click)="sidNavType = 1; drawer.open()">
                    Enter Activation Code
                  </a>
                </div>
                <ng-container *ngIf="loginUser">
                  <a (click)="initiatePayment()"
                    class="mat-button mat-focus-indicator letter-spacing-15 text-uppercase font-xss mat-raised-button mat-button-base mat-primary mr-8 cursor-pointer"
                    target="_blank">
                    BUY NOW
                  </a>
                </ng-container>
                <ng-container *ngIf="!loginUser">
                  <a (click)="loginInitiateForBuy(drawer)"
                    class="mat-button mat-focus-indicator letter-spacing-15 text-uppercase font-xss mat-raised-button mat-button-base mat-primary mr-8 cursor-pointer">
                    BUY NOW
                  </a>
                </ng-container>
              </div>
            </div>
            <div *ngIf="packages.length == 0">
              <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
                <mat-icon class="border-radius-full">search</mat-icon>
                <p class="text-center mt-2 font-base color-secondary">
                  No Packages available. <br />Please explore the by click on
                  the below button.
                </p>
                <button mat-raised-button color="primary" class="mt-2" routerLink="/our-offering/bot-based-courses">
                  Explore
                </button>
              </div>
            </div>
          </ngcontainer>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>
