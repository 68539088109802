<ng-container *ngIf="activeTopic">
  <mat-drawer-container autosize class="h-screen">
    <mat-drawer #drawer mode="over" class="quiz-history" position="end">
      <div class="container">
        <div class="milestones">
          <article *ngFor="let item of quizHistory" (click)="goToResult(item)" class="cursor-pointer">
            <span class="mat-color-primary">{{item.created_at | date: 'medium'}}</span>
            <h2>{{item.properties.correctAnswersCount+"/"+item.properties.totalQuestions}}</h2>
            <div>
              <p> <span class="color-secondary">You completed the quiz in <strong>{{
                    Math.floor(item.properties.completionTime / 60) }}</strong> minutes and <strong>{{
                    item.properties.completionTime % 60 }}</strong> seconds.</span>
              </p>
            </div>
          </article>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <mat-card class="mx-4 xs:m-0 mt-4 relative" style="height: calc(100vh - 32px);">
        <section class="pb-4 flex justify-between">
          <div class="flex items-center">
            <button mat-mini-fab class="mr-4 mat-elevation-z1"
              (click)="back()"><mat-icon>keyboard_backspace</mat-icon></button>
            <div class="mat-card-container">
              <mat-card-title>{{activeTopic.name}}</mat-card-title>
              <mat-card-subtitle class="mb-0">
                <span class="subtitle1"> {{course.name}}</span>,
                <span class="subtitle2">{{module.name}}</span>,
                <span class="subtitle2">{{chapter?.title}}</span>
              </mat-card-subtitle>
            </div>
          </div>
          <div>
            <mat-chip-list aria-label="History of the Quiz" class="mb-12 self-end">
              <mat-chip color="primary" selected class="cursor-pointer" (click)="drawer.toggle()">History</mat-chip>
            </mat-chip-list>
          </div>
        </section>
        <mat-card-content>
          <div class="flex justify-between">
            <div class="w-1/2" style="line-height: 1.8rem;">
              <h2 class="mb-2">Welcome to the Quiz!</h2>
              <p class=" mb-2">
                This quiz is related to the topics your have learned. Please read the below instruction carefully and
                complete the quiz. Please note that completing the quiz is essential to proceed with the next topic.
              </p>
              <ul class="mb-2">
                <li>This is not a timed quiz however there is maximum time limit of 5 minutes apply, after which the
                  quiz stops and answers would be auto-submitted. We would also record the time taken for you to attempt
                  the quiz.</li>
                <li>All the questions in the quiz are of multiple choice type. Please select appropriate answer. </li>
                <li>In case you select a wrong answer, the app would display the correct answer for you. This way you
                  could learn while attempting the quiz.</li>
                <li>All the questions in the quiz carry equal weightage. There is no negative scoring.</li>
                <li>Your score will increase for each correct answer. In case you are not sure about the answer we
                  suggest you to guess the closet one.</li>
                <li>Bonus points would be given for those who complete the quiz, below the average time. </li>
                <li>You could refer to your notes to answer the questions. However mind the allocated time. </li>
                <li>You could retake the assessment any number of times, however please note that we would only consider
                  your first attempt score and timing.</li>
                <li>If you have any issues related to the quiz you could write to us at support@schoolforai.com</li>
              </ul>
              <p>Good luck! Start the quiz when you are ready. </p>
            </div>
            <div class="flex items-center flex-column justify-center" style="width: 45%;">
              <img src="assets/images/question_mask.png" alt="Question Masking" />
            </div>
            <div>

            </div>
          </div>


        </mat-card-content>
        <mat-card-actions class="absolute bottom-0-em left-0-px p-4 w-full m-0">
          <button mat-raised-button type="button" disableRipple="false" (click)="startQuiz()"
            color="course{{course.id}}-button">Start the Quiz!</button>
        </mat-card-actions>
      </mat-card>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>