<mat-drawer-container class="" autosize>
  <mat-drawer #drawer class="" mode="over" position="end">
    <ng-container *ngIf="newProject">
      <div style="width: 500px">

        <form>
          <div class="px-4 py-4 event-details-popup">
            <h3 class="font-xl mb-4">
              Create AI Project
            </h3>

            <mat-form-field class="w-full" appearance="outline" floatLabel="always">
              <mat-label>Project Title</mat-label>
              <input matInput placeholder="Ex: AI Model" [formControl]="titleControl" color="#fff" type="text"
                maxlength="254" id="projectTitle" #titleInput />
              <mat-icon matSuffix class="font-lg">alternate_email</mat-icon>
              <mat-error *ngIf="titleControl.hasError('required')" class="mb-2">
                Title is <strong>required</strong>
              </mat-error>

            </mat-form-field>

            <mat-form-field class="w-full" appearance="outline" floatLabel="always">
              <mat-label>Tag Line</mat-label>
              <input matInput placeholder="Ex: AI Bot" [formControl]="taglineControl" color="#fff" type="text"
                maxlength="254" id="projectTitle" #tagInput />
              <mat-icon matSuffix class="font-lg">tag</mat-icon>
              <mat-error *ngIf="taglineControl.hasError('required')" class="mb-2">
                Title is <strong>required</strong>
              </mat-error>

            </mat-form-field>


          </div>
          <div class="upload-wrapper-section px-4">
            <input type="file" name="files" id="file" style="display: none" #submitFile
              (change)="uploadSource($event)" />
            <h3 class="modal-title">Model</h3>
            <p class="modal-description">Attach the zip file below (ZIP file contains the detect.tflite, labels.txt)</p>
            <button class="upload-area" (click)="uploadDocument(submitFile)">
              <span class="upload-area-icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="340.531"
                  height="419.116" viewBox="0 0 340.531 419.116">
                  <g id="files-new" clip-path="url(#clip-files-new)">
                    <path id="Union_2" data-name="Union 2"
                      d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z"
                      transform="translate(2944 428)" fill="var(--c-action-primary)" />
                  </g>
                </svg>
              </span>
              <!-- <span class="upload-area-title"
                  >Drag file(s) here to upload.</span
                > -->
              <span class="upload-area-title">
                You can select a file by <br /><strong>clicking here</strong>
              </span>
            </button>
          </div>

          <button mat-raised-button color="primary"
            class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none"
            style="height: 3.6rem" (click)="create()" type="button">
            SAVE
          </button>

        </form>

      </div>
    </ng-container>
    <ng-container *ngIf="editProject">
      <div style="width: 500px">

        <form>
          <div class="px-4 py-4 event-details-popup">
            <h3 class="font-xl mb-4">
              Edit AI Project
            </h3>

            <mat-form-field class="w-full" appearance="outline" floatLabel="always">
              <mat-label>Project Title</mat-label>
              <input matInput placeholder="Ex: AI Model" [formControl]="titleControl" color="#fff" type="text"
                maxlength="254" id="projectTitle" #titleInput />
              <mat-icon matSuffix class="font-lg">alternate_email</mat-icon>
              <mat-error *ngIf="titleControl.hasError('required')" class="mb-2">
                Title is <strong>required</strong>
              </mat-error>

            </mat-form-field>

            <mat-form-field class="w-full" appearance="outline" floatLabel="always">
              <mat-label>Tag Line</mat-label>
              <input matInput placeholder="Ex: AI Bot" [formControl]="taglineControl" color="#fff" type="text"
                maxlength="254" id="projectTitle" #tagInput />
              <mat-icon matSuffix class="font-lg">tag</mat-icon>
              <mat-error *ngIf="taglineControl.hasError('required')" class="mb-2">
                Title is <strong>required</strong>
              </mat-error>

            </mat-form-field>


          </div>
          <div class="upload-wrapper-section px-4">
            <input type="file" name="files" id="file" style="display: none" #submitFile
              (change)="uploadSource($event)" />
            <h3 class="modal-title">Model</h3>
            <p class="modal-description">Attach the zip file below (ZIP file contains the detect.tflite, labels.txt)</p>
            <button class="upload-area" (click)="uploadDocument(submitFile)">
              <span class="upload-area-icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="340.531"
                  height="419.116" viewBox="0 0 340.531 419.116">
                  <g id="files-new" clip-path="url(#clip-files-new)">
                    <path id="Union_2" data-name="Union 2"
                      d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z"
                      transform="translate(2944 428)" fill="var(--c-action-primary)" />
                  </g>
                </svg>
              </span>
              <!-- <span class="upload-area-title"
                  >Drag file(s) here to upload.</span
                > -->
              <span class="upload-area-title">
                You can select a file by <br /><strong>clicking here</strong>
              </span>
            </button>
          </div>

          <button mat-raised-button color="primary"
            class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none"
            style="height: 3.6rem" (click)="edit()" type="button">
            SAVE
          </button>

        </form>

      </div>
    </ng-container>
  </mat-drawer>

  <ng-container *ngIf="!processing && userProjects.length > 0">
    <div class="flex" style="
    background-color: #ffe9e3;
    color: var(--primary-color);
    align-items: center;
    padding: 0.5rem 1rem;
    justify-content: flex-end;
    font-weight: 600;
  ">
      <button mat-raised-button color="primary" style="
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background-color: #2c3e50;
  " (click)="createNewProject()">
        Create Project
      </button>
    </div>

  </ng-container>


  <div style="background-color: #fff;height: 80.68vh" class="grid has-4-columns xs:has-1-columns">

    <div class="column-start-1 column-end-3 xs:column-end-4 md:column-end-4 xl:column-end-3 ">
      <ng-container *ngIf="processing">
        <div class="flex flex-column items-center justify-center relative" style="height: 80.68vh;">
          <mat-spinner strokeWidth="3"></mat-spinner> <img src="assets/images/icon.png" class="absolute" width="64">
        </div>
      </ng-container>

      <ng-container *ngIf="!processing && userProjects.length > 0">

        <div class="models-section flex mx-8 flex-wrap mt-8">
          <ng-container *ngFor="let model of userProjects">
            <div class="item">
              <mat-card class="mat-card border-radius-lg ">
                <mat-card-content>
                  <div class="absolute right-10-px top-0-px">
                    <button mat-icon-button (click)="editProjectFunc(model)"><mat-icon class="material-symbols-outlined"
                        style="font-size:20px">edit</mat-icon></button>
                    <button mat-icon-button (click)="deleteProjectFunc(model)"><mat-icon
                        class="material-symbols-outlined" style="font-size:20px">delete</mat-icon></button>
                  </div>
                  <div class="icon-info">
                    <div class="border-radius-full">
                      <ng-container *ngIf="model.icon">
                        <img [src]="model.icon_p" width="32" height="32" />
                      </ng-container>
                      <ng-container *ngIf="!model.icon">
                        <mat-icon class="material-symbols-outlined">rainy_light</mat-icon>
                      </ng-container>

                    </div>
                  </div>
                  <h3 class="mt-2">{{model.name}}</h3>
                  <p class="mt-1 line-clamp-3 color-secondary font-xs" style="height: 4.2em;">{{model.tag_line}}
                  </p>

                </mat-card-content>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!processing && userProjects.length == 0">
        <div class="empty-state flex items-center px-6 flex-column justify-center" style="height: 87.2vh;">
          <mat-icon class="border-radius-full">search</mat-icon>
          <p class="text-center mt-2 font-base color-secondary">
            You did not have any projects yet.
            <br />Please create the project by click on the below button.
          </p>
          <button mat-raised-button color="primary" class="mt-2 letter-spacing-10" (click)="createNewProject()">
            CREATE NEW PROJECT
          </button>
        </div>
      </ng-container>
    </div>

    <div class="h-auto mt-8 pr-4 column-start-4 column-end-4 xs:display-none md:display-none xl:display-block">

      <div class="flex border-radius-lg flex-column py-6" style="line-height: 1.8rem; background: antiquewhite">
        <h5 class="mb-2 pl-6 pr-6">By following these steps, you’ll be able to create, predict, and control your
          Robotics
          Bot, bringing your AI projects to life!</h5>
        <ul class="pl-10 pr-4 font-xs">
          <li class="mb-2">
            <b>Train your model</b> using Teachable Machine.
          </li>
          <li class="mb-2">
            <b>Export and upload</b> the TensorFlow Lite file to the AI Workspace.
          </li>
          <li class="mb-2">
            <b>Access the model</b> from the AITINKR mobile app.
          </li>
          <li class="mb-2">
            <b>Predict objects</b> using the model.
          </li>
          <li class="mb-2">
            <b>Send labels to the Bot</b> via Bluetooth.
          </li>
          <li class="mb-2">
            <b>Watch the Bot</b> perform actions based on the prediction.
          </li>
        </ul>
      </div>
    </div>


  </div>

</mat-drawer-container>
