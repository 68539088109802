import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { FormControl, Validators } from '@angular/forms';
import { HttpService } from '../../../core/services/http.service';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit {
  userProjects: any = [];

  processing: boolean = true;

  newProject: boolean = false;

  editProject: boolean = false;

  activeModel: any;

  @ViewChild(MatDrawer) sidenav: MatDrawer;

  titleControl = new FormControl('', [Validators.required]);
  taglineControl = new FormControl('', [Validators.required]);

  loginUser: any;

  selectedModelFile: any;

  constructor(private auth: AuthService, private http: HttpService) {}

  ngOnInit(): void {
    this.loginUser = this.auth.loginUser;
    this.getProjects();
  }

  getProjects() {
    this.http.get('user_custom_ai_models').subscribe((res: any) => {
      console.log(res.data);
      this.userProjects = res.data;
      this.processing = false;
    });
  }

  createNewProject() {
    this.activeModel = null;
    this.editProject = false;
    this.newProject = true;
    this.titleControl.reset();
    this.taglineControl.reset();
    this.sidenav.open();
  }

  editProjectFunc(model) {
    this.activeModel = model;
    this.titleControl.setValue(model.name);
    this.taglineControl.setValue(model.tag_line);
    this.newProject = false;
    this.editProject = true;
    this.sidenav.open();
  }

  deleteProjectFunc(model) {
    if (confirm('Are you sure, you want to delete?')) {
      this.http
        .post('delete/user_custom_ai_models', { id: model.id })
        .subscribe((res) => {
          this.getProjects();
        });
    }
  }

  create() {
    if (!this.titleControl.valid || !this.taglineControl.valid) return;
    let data = {
      name: this.titleControl.value,
      tag_line: this.taglineControl.value,
    };
    this.http.post('user_custom_ai_models', data).subscribe((res) => {
      this.getProjects();
      if (this.selectedModelFile)
        this.upload(this.selectedModelFile, res.data.id);
      this.sidenav.close();
    });
  }

  edit() {
    if (!this.titleControl.valid || !this.taglineControl.valid) return;
    let data = {
      name: this.titleControl.value,
      tag_line: this.taglineControl.value,
    };
    this.http
      .put('user_custom_ai_models/' + this.activeModel.id, data)
      .subscribe((res) => {
        this.getProjects();
        if (this.selectedModelFile)
          this.upload(this.selectedModelFile, res.data.id);
        this.sidenav.close();
      });
  }

  uploadDocument(submitFile) {
    submitFile.click();
  }

  uploadSource(e) {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      this.selectedModelFile = e.target.files[0];
      //this.upload(e.target.files[0]);
    }
    e.target.value = '';
  }

  upload(source, projectId) {
    let that = this;
    let ajaxData = new FormData();
    ajaxData.append('file[0]', source);
    var reader = new FileReader();

    reader.readAsDataURL(source);
    ajaxData.append(
      'upload_path',
      '/user/user_custom_ai_models/' + this.loginUser.id + '/' + projectId + '/'
    );
    ajaxData.append('custom_attrs', '[]');
    ajaxData.append('random_names', 'false');
    this.http.upload('media/upload', ajaxData).subscribe((res: any) => {
      if (res.length == 1 && res[0].success) {
        this.http
          .put('user_custom_ai_models/' + projectId, {
            model_path: res[0].file_name,
          })
          .subscribe((res) => {
            this.selectedModelFile = null;
            this.getProjects();
          });
      }
    });
  }
}
